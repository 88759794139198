import React, { useContext } from "react";
import { Link } from "gatsby";
import { LocaleContext } from "context/locale-context";
import locales from "../../config/i18n";

// Use the globally available context to choose the right path
function LocalizedLink({ to, ...props }) {
  const locale = useContext(LocaleContext);
  const isEng = locale === "en";
  const isIndex = to === `/`;
  const path = isIndex ? (isEng ? "/" : "/ar") : `/${locale}/${to}`;

  return <Link {...props} to={path} />;
}

export default LocalizedLink;
