import React from "react";
import { startsWith } from "ramda";
import LocalizedLink from "components/localizedLink";

const isHash = str => /^#/.test(str);
const isInternal = to => !startsWith("http", to);

// Only use <LocalizedLink /> for internal links
const MdxLink = ({ href, ...props }) => {
  return isHash(href) || !isInternal(href) ? (
    <a {...props} href={href} />
  ) : (
    <LocalizedLink {...props} to={href} />
  );
};

export default MdxLink;
