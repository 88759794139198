import format from "date-fns/format";
import { identical, groupBy, slice } from "ramda";
import { arSA, enUS } from "date-fns/locale";

function localizedDate(unixStampedDate, format, isRTL) {
  const date = new Date(unixStampedDate);
  const options = {
    ...(format.includes("d") && { day: "numeric" }),
    ...(format.includes("m") && { month: "long" }),
    ...(format.includes("M") && { month: "2-digit" }),
    ...(format.includes("y") && { year: "numeric" }),
    ...(format.includes("w") && { weekday: "long" })
  };
  return isRTL
    ? date.toLocaleDateString("ar-SY", options)
    : date.toLocaleDateString("en-US", options);
}

function formatDate(date, locale = "en") {
  const isAr = locale === "ar";
  return format(new Date(date), "eee MMM dd yyyy", {
    locale: isAr ? arSA : enUS
  });
}

const breakpoints = [576, 768, 992, 1280, 1400];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const isTrue = identical(true);

const feedsBySite = data => groupBy(item => item.site)(data);

const slicedFeeds = (feeds, s, y) => {
  const { "Syrian Archive": syrianFeedData, "Yemeni Archive": yemeniFeedData } = feedsBySite(feeds);

  return [
    ...(syrianFeedData ? slice(0, s, syrianFeedData) : []),
    ...(yemeniFeedData ? slice(0, y, yemeniFeedData) : [])
  ];
}

const regex = /^https.*org\//g; // matches our sites links
const extractUrl = link => link.match(regex)[0];

export { localizedDate, mq, formatDate, isTrue, slicedFeeds, extractUrl };
