import styled from "@emotion/styled";
import { jsx, css } from "@emotion/core";
import MdxLink from "components/mdxLink";
import { mq } from "utils/helper";
import { rhythm } from "utils/typography";

export const colors = {
  primary: "#00FFFF",
  light: "#ffffff",
  dark: "#000000",
  bg: "#f5f5f5"
};

export const H2 = styled.h2({
  color: colors.primary,
  margin: 0,
  alignSelf: "start",
  maxWidth: "30%",
  fontSize: "48.83px",
  letterSpacing: "0.59px",
  [mq[1]]: {
    fontSize: "40px",
    maxWidth: "unset"
  }
});

export const designSystem = {
  a: styled(MdxLink)({
    color: colors.primary,
    fontWeight: "bold"
  }),
  p: styled.p({
    color: colors.light,
    lineHeight: "26px"
  }),
  // H1 should be used only for creating TOC of Methods page
  h1: styled.h1({
    color: colors.light,
    opacity: 0.7,
    display: "none"
  }),
  h2: styled.h2({
    color: colors.light,
    opacity: 0.7
  }),
  h3: styled.h3({
    color: colors.light,
    opacity: 0.7
  }),
  h4: styled.h4({
    color: colors.light,
    opacity: 0.7
  }),
  h5: styled.h5({
    color: colors.light,
    opacity: 0.7
  }),
  li: styled.li({ color: colors.light }),
  blockquote: styled.blockquote(({ isRTL }) => ({
    color: colors.light,
    fontWeight: "bold",
    lineHeight: "43px",
    fontSize: "25px",
    borderLeft: isRTL || "6px solid white",
    borderRight: isRTL && "6px solid white",
    paddingRight: isRTL && "1rem",
    margin: rhythm(1),
    "& p": {
      lineHeight: "43px"
    }
  })),
  ul: styled.ul({
    marginLeft: "1.5rem"
  })
};

export const firstBox = css({
  "ul:first-of-type": {
    border: `6px solid ${colors.dark}`,
    boxShadow: "-5px 5px 12px 0 rgba(0,0,0,0.25)",
    padding: `${rhythm(1)} ${rhythm(2)}`,
    "& li": {
      listStyle: "none",
      margin: 0,
      "&::before": {
        content: '"•"',
        color: colors.primary,
        display: "inline-block",
        width: "0.8em",
        fontSize: "2em"
      }
    },
    [mq[0]]: {
      paddingLeft: rhythm(1),
      paddingRight: rhythm(1),
      marginLeft: 0
    }
  }
});

export const PageNav = styled.div({
  display: "flex",
  color: colors.primary,
  alignItems: "center",
  width: "830px",
  justifyContent: "space-between",
  alignSelf: "start",
  flexWrap: "wrap",
  [mq[1]]: {
    display: "block"
  },
  [mq[2]]: {
    justifyContent: "start"
  }
});

export const NavItem = styled.a({
  border: `3px solid ${colors.primary}`,
  color: colors.light,
  opacity: 0.8,
  fontSize: "22px",
  padding: "0.2rem 0.6rem 0rem 0.6rem",
  whiteSpace: "no-wrap",
  ":hover": { color: colors.primary },
  [mq[1]]: {
    border: "none",
    display: "block",
    textDecoration: "underline"
  },
  [mq[2]]: {
    marginBottom: "0.6rem",
    marginRight: "0.6rem"
  }
});
