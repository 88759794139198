import React from "react";
import { toLower, replace } from "ramda";
import { colors } from "../style/theme";

function TitleWithIcon({ title, icon }) {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        marginBottom: "1.5rem",
        marginTop: "3rem"
      }}
    >
      <img src={icon} css={{ margin: 0 }} />
      <h2
        css={{
          paddingTop: "1.2rem",
          margin: "1rem",
          marginBottom: 0,
          marginTop: "0.5rem",
          color: colors.light
        }}
        id={toLower(replace(/\s/g, "-", title))}
      >
        {title}
      </h2>
    </div>
  );
}

export default TitleWithIcon;
